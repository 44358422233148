import {
  type IncludeArr,
  isExpanded,
  Title,
  type TitleEntity,
  type TitleFuzzySearchListParams,
  type TitleGroupedChaptersParams,
  type TitleListParams,
} from "~/src/api";
import { QueryClient, queryOptions } from "@tanstack/vue-query";
import { useCacheTimings } from "~/composables/cacheTiming";
import { type AuthFunction, toUnordered } from "~/utils/tanstack/global";

export const cacheTitle = (
  client: () => QueryClient,
  title: TitleEntity,
  authed = false,
) => {
  const qc = client();
  const expandedRelations = new Set(
    title.relationships.filter(isExpanded).map((rel) => rel.type),
  );

  qc.setQueryData(
    [
      "title",
      "entity",
      title.id,
      {
        includes: toUnordered("includes", Array.from(expandedRelations)),
        authed: authed || undefined,
      },
    ],
    title,
  );
};

export const useTitleFuzzyList = (params: TitleFuzzySearchListParams = {}) => {
  const unorderedParams = {
    ...params,
    ids: params.ids ? toUnordered("ids", params.ids) : undefined,
    includes: params.includes
      ? toUnordered("includes", params.includes)
      : undefined,
    originalLanguages: params.originalLanguages
      ? toUnordered("langs", params.originalLanguages)
      : undefined,
    availableTranslatedLanguages: params.availableTranslatedLanguages
      ? toUnordered("atl", params.availableTranslatedLanguages)
      : undefined,
    contentRatings: params.contentRatings
      ? toUnordered("content", params.contentRatings)
      : undefined,
    publicationDemographics: params.publicationDemographics
      ? toUnordered("demo", params.publicationDemographics)
      : undefined,
    publicationStatuses: params.publicationStatuses
      ? toUnordered("status", params.publicationStatuses)
      : undefined,
    readingModes: params.readingModes
      ? toUnordered("mode", params.readingModes)
      : undefined,
  };

  return queryOptions({
    queryKey: ["title", "collection", unorderedParams],
    queryFn: async ({ client }) => {
      const res = await Title.fuzzySearch(params);
      res.data.forEach((title) =>
        cacheTitle(() => client as QueryClient, title),
      );
      return res;
    },
    staleTime: useCacheTimings().collection.title,
  });
};

export const useTitleList = (
  params: TitleListParams = {},
  auth?: AuthFunction,
) => {
  const unorderedParams = {
    ...params,
    includes: params.includes
      ? toUnordered("includes", params.includes)
      : undefined,
    originalLanguage: params.originalLanguage
      ? toUnordered("langs", params.originalLanguage)
      : undefined,
    ids: params.ids ? toUnordered("ids", params.ids) : undefined,
    states: params.states ? toUnordered("states", params.states) : undefined,
    authed: !!auth || undefined,
  };

  return queryOptions({
    queryKey: ["title", "collection", unorderedParams],
    queryFn: async ({ client }) => {
      const res = await Title.search(params, (await auth?.()) || undefined);
      res.data.forEach((title) =>
        cacheTitle(() => client as QueryClient, title, unorderedParams.authed),
      );
      return res;
    },
    staleTime: useCacheTimings().collection.title,
  });
};

export const useTitleGet = (
  id: MaybeRefOrGetter<string>,
  includes: IncludeArr<TitleEntity> = [],
  auth?: AuthFunction,
) => {
  return queryOptions({
    queryKey: [
      "title",
      "entity",
      id,
      {
        includes: toUnordered("includes", includes),
        authed: !!auth || undefined,
      },
    ],
    queryFn: async () => {
      const idValue = toValue(id);
      if (!idValue) throw new Error("Title ID not provided");
      return await Title.get(idValue, includes, (await auth?.()) || undefined);
    },
    staleTime: useCacheTimings().entity.title,
  });
};

export const useTitleGroupedChapters = (
  titleId: MaybeRefOrGetter<string>,
  params?: TitleGroupedChaptersParams,
  auth?: AuthFunction,
) => {
  const unorderedParams = {
    ...params,
    organizationIds: params?.organizationIds
      ? toUnordered("orgs", params.organizationIds)
      : undefined,
    translatedLanguages: params?.translatedLanguages
      ? toUnordered("langs", params.translatedLanguages)
      : undefined,
    authed: !!auth || undefined,
  };

  return queryOptions({
    queryKey: ["grouped_chapter_list", "entity", titleId, unorderedParams],
    queryFn: async () => {
      const titleIdValue = toValue(titleId);
      if (!titleIdValue) throw new Error("Title ID not provided");

      return await Title.getChaptersList(
        titleIdValue,
        params,
        (await auth?.()) || undefined,
      );
    },
    staleTime: useCacheTimings().entity.grouped_chapter_list,
  });
};
